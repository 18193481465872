import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'
import RichText from '../../components/RichText'
import StartNav from '../../components/StartNav'

import useStyles from './styles'


const OnboardingCodeTemplate = (props) => {
  const classes = useStyles(props)
  const { className, code, text, startNavProps } = props

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array.from($animatedWrapper.current.children).filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])

  return (
    <main
      className={cx(className, classes.container)}
    >
      <div
        className={classes.wrapper}
        ref={$animatedWrapper}
      >
        {code && (
          <div className={classes.code}>
            {code}
          </div>
        )}
        {text && (
          <RichText
            className={classes.text}
            json={text}
          />
        )}
        <div className={classes.navigation}>
          {startNavProps && (
            <StartNav
              {...startNavProps}
              className={classes.navigationButtons}
            />
          )}
        </div>
      </div>
    </main>
  )
}

export const OnboardingCodeTemplatePropTypes = {
  className: PropTypes.string,
  code: PropTypes.string,
  text: PropTypes.shape(PropTypes.object),
  startNavProps: PropTypes.shape(),
}

OnboardingCodeTemplate.propTypes = OnboardingCodeTemplatePropTypes

OnboardingCodeTemplate.defaultProps = {
  className: null,
  code: '',
  text: null,
  startNavProps: null,
}

export default withMemo()(OnboardingCodeTemplate)
