import React, { useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import { useIntl } from '../../intl/intl'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import LangSelector from '../../components/LangSelector'
import useLangSelector from '../../hooks/useLangSelector'
import useConfiguration from '../../hooks/useConfiguration'
import useLocalStorage from '../../hooks/useLocalStorage'
import OnboardingCodeTemplate from '../../templates/OnboardingCode'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'


const Code = (props) => {
  const t = useIntl()
  const { data, pageContext, location } = props
  const { configuration, seoFallback } = useConfiguration()
  const { setUserOnboarded } = useLocalStorage()
  const { langSelectorProps } = useLangSelector({ links: configuration?.locales ?? [], locale: pageContext?.language ?? '' })

  const codeTemplateProps = useMemo(() => ({
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: seoFallback?.title ?? '',
        description: seoFallback?.description?.description ?? '',
        image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
        robots: 'noindex, follow',
      },
    },
    langSelectorProps,
    headerProps: {
      isFallHidden: true,
      linkLogoProps: {
        link: t('balenciaga_logo_link'),
        target: '_blank',
      },
    },
    onboardingCodeProps: {
      code: t('vr_code'),
      text: data.contentfulOnboarding.codeInstructions.json,
      startNavProps: {
        buttons: [
          {
            text: t('go_to_lookbook'),
            variant: 'bigtransparent',
            gatsbyLink: {
              route: router(routes.vipLooks.path),
            },
          },
        ],
        skipLinks: [
          {
            text: t('go_back'),
            route: router(routes.vr.path),
          },
        ],
      },
    },
  }), [pageContext.language, seoFallback.title, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, location.href, langSelectorProps, t, data.contentfulOnboarding.codeInstructions.json])

  useEffect(() => {
    setUserOnboarded()
  }, [setUserOnboarded])

  return (
    <Layout
      header={(
        <Header
          navigation={
            pageContext?.language !== 'zh' && <LangSelector {...codeTemplateProps.langSelectorProps} />
          }
          {...codeTemplateProps.headerProps}
        />
      )}
      {...codeTemplateProps.layoutProps}
    >
      <OnboardingCodeTemplate {...codeTemplateProps.onboardingCodeProps} />
    </Layout>
  )
}

Code.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}
Code.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(Code)))

export const query = graphql`
   query code($language: String){
    contentfulOnboarding(node_locale: { eq: $language }) {
      ...balenciagaF21Onboarding
    }
  }
`
