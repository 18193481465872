import { createUseStyles } from 'react-jss'

import { colors, desktop } from '../../theme'


export default createUseStyles({
  container: {
    alignSelf: 'flex-end',
    paddingTop: '2rem',
    paddingBottom: '4.5rem',
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    paddingLeft: '2.8rem',
    paddingRight: '2.8rem',
    minHeight: 'calc(var(--vh, 1vh) * 50)',
    fallbacks: {
      minHeight: '50vh',
    },
    '& > *': {
      width: '100%',
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '4rem',
    },
    '& > $navigation': {
      marginTop: 'auto',
    },
  },
  code: {
    fontSize: '3.6rem',
    ...desktop({
      fontSize: '5.2rem',
    }),
  },
  text: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    fontSize: '1.2rem',
    lineHeight: 1.3,
    ...desktop({
      fontSize: '1.6rem',
    }),
  },
  navigation: {
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
  },
  navigationBack: {
    display: 'inline-flex',
    paddingBottom: '0.8rem',
    borderBottom: `1px solid ${colors.pureWhite}`,
    fontSize: '1.2rem',
    lineHeight: 1.3,
    color: colors.pureWhite,
  },
})
